import React from "react";
import { graphql } from "gatsby";
import BlogEntry, { BlogAdEntry } from "../components/BlogEntry/BlogEntry";
import GenericContentIndexPage from "./generic/genericContentIndexTemplate";
import { transformEdgesNodes } from "../components/utils";
import withLayoutAndData from "./generic/withLayoutAndData";

export const BlogIndexPage = ({ page, preview }) =>
	<GenericContentIndexPage page={page}
		ContentItemComponent={BlogEntry}
		AdContentItemComponent={BlogAdEntry}
		showAd
		preview={preview} />;

export const pageQuery = graphql`
	query BlogIndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "blog-index-page" } }) {
			html
			frontmatter {
        		title
        		heading
			}
			fields {
				slug
			}
			...RemoteBannerImage
		}
		items: allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
		) {
			edges {
				node {
					excerpt(pruneLength: 400)
					id
					fields {
						slug
					}
					frontmatter {
						title
						date(formatString: "MMMM DD, YYYY")
						featuredpost
						description
					}
					...RemoteBannerImageSmall
				}
			}
		}
	}`;

export const postTransform = (node) => ({
	id: node.id,
	excerpt: node.excerpt,
});

export default withLayoutAndData(
	({ data }) => ({
		page: {
			...data.markdownRemark.frontmatter,
			...data.markdownRemark.fields,
			content: data.markdownRemark.html,
			items: transformEdgesNodes(data.items, postTransform),
		}
	})
)(BlogIndexPage);
