import React from "react";
import styled from "styled-components";
import { Heading, Text } from "grommet";
import AdSenseAd from "../AdNetworks/AdSenseAd";
import Image from "../Image/Image";
import NavLink from "../Link/NavLink";
import { HTMLContent } from "../Content";
import { getHebrewDate } from "../utils";
import { color, border, breakpoint } from "../../theme";
import getIsNewItem from "../../common/getIsNewItem";

const Container = styled.article`
	display: flex;
	flex-direction: column;
	min-width: 320px;
	max-width: 320px;
	margin: 0 0 20px 10px;
	padding: 4px;
	align-self: flex-start;

	:hover {
		${color("light-1", "background-color")}
	}

	${breakpoint("phone")`
		max-width: 100%;
		width: 100%;
		${border({ style: "dotted" })`bottom`}
	`}
`;

const EntryContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
`;

const EntryImage = styled(Image)`
	width: 120px;
	max-height: 90px;
`;

const RightSide = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 10px;
`;

const LeftSide = styled.div`
	display: flex;
	flex-direction: column;
`;

const HeadingLink = styled(NavLink)`
	display: flex;
	align-items: center;
	margin-bottom: 12px;
`;

const EntryHeading = styled(Heading)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 230px;

	${breakpoint("phone")`
		max-width: 320px;
	`}
`;

const EntryText = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	line-height:16px;

	p {
		margin: 0;
	}
`;

const EntryDate = styled(Heading)`
	font-weight: bold;
	margin: 4px 6px 0 0;
	padding-right: 6px;
	${border({ color: "brand-dark" })`right`}
`;

export const BlogAdEntry = () => {
	return <Container>
		<AdSenseAd layoutKey="-gt+l-2q-a5+tm" slot="3585185358" />
	</Container>;
};

const BlogEntry = ({ item }) => {
	return <Container>

		<HeadingLink to={item.slug}>
			<EntryHeading level={3} size="medium" title={item.title} margin="none">
				{item.title}
			</EntryHeading>
			<EntryDate level="4" size="small" margin="none">
				{getHebrewDate(new Date(item.date))}
			</EntryDate>
		</HeadingLink>

		<EntryContent>
			<RightSide>
				<NavLink to={item.slug}>
					<EntryImage
						image={item.image}
						alt={item.imageAlt || item.title}
						showNew={getIsNewItem(item)}
					/>
				</NavLink>
			</RightSide>
			<LeftSide>
				<NavLink to={item.slug} $noHoverStyles>
					<EntryText size="small" wordBreak="normal">
						{item.description ? <HTMLContent content={item.description} /> : item.excerpt}
					</EntryText>
				</NavLink>
			</LeftSide>
		</EntryContent>
	</Container>;
};

export default BlogEntry;
